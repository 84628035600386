<template>
    <div>
        <CRow>
            <CCol lg="12" style="margin-bottom:10px">
                <CButton type="submit" size="sm" class="float-left btn-primary" @click="$router.go(-1)" ><CIcon name="cil-list"/> 목록</CButton>
                <CButton v-if="!editable && $hasWritePermission('202')" type="submit" size="sm" color="primary" class="float-right" @click="changeToModifyMode"><CIcon name="cil-pencil"/> 수정</CButton>
                <form @submit.prevent="formSubmit" method="post">
                <CButton v-if="editable" type="submit" size="sm" color="primary" class="float-right" :disabled="afterClick">
                    <CIcon name="cil-pencil"/> 저장
                </CButton>
                </form>
                    <CButton v-if="editable" type="submit" size="sm" class="float-right" color="danger" @click="cancelAdminInfo" ><CIcon name="cil-x-circle"/> 취소</CButton> 
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
            <CCard>
               <CCardHeader>
                    <strong> 앱 정보 </strong>
               </CCardHeader>
                <CCardBody>
                    <CRow>
                        <div v-if="previewImgUrl" id="divImage">
                        <CCol sm="1" class="mr-3">
                            <CImg 
                                :src="previewImgUrl"
                                block
                                class="mb-1"
                                width="130px"
                                height="130px"/>                          
                        </CCol>
                        </div>
                        <CCol sm="10">
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>앱 이름(*)</strong>
                                </CCol>
                                <CCol sm="5">
                                    <CInput 
                                        horizontal
                                        v-model="appNm"
                                        :readonly="!editable"
                                    />
                                </CCol>
                            </CRow>
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>OS</strong>
                                </CCol>
                                <CCol sm="5">
                                    <img
                                        :src="$renderer('osType', 2, this.osType)"
                                        width=25
                                        height=25
                                    />
                                </CCol>
                            </CRow>    
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>패키지명</strong>
                                </CCol>
                                <CCol sm="5">
                                {{ packageNm }}
                                </CCol>
                            </CRow>
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>버전</strong>
                                </CCol>
                                <CCol sm="5">
                                {{ appVersion }}
                                </CCol>
                            </CRow>
                            <div v-if="!storeYn">
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>파일명</strong>
                                </CCol>
                                <CCol sm="10">
                                {{ fileNm }}
                                </CCol>
                            </CRow>
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>사이즈</strong>
                                </CCol>
                                <CCol sm="5">
                                {{ fileSize }} MB
                                </CCol>
                            </CRow>   
                            </div>       
                            <div v-if="osType=='0'">                  
                            <CRow class="mb-2" style="vertical-align: middle">                                
                                <CCol sm="2">
                                <strong>Google Play Store 여부</strong>
                                </CCol>
                                <CCol sm="5">
                                {{$renderer('storeYn', 0, storeYn)}}
                                </CCol>
                            </CRow>
                            </div>
                            <div v-if="osType=='1'">            
                            <CRow class="mb-2">                                                               
                                <CCol sm="2">
                                <strong>Apple App Store 여부</strong>
                                </CCol>
                                <CCol sm="6">
                                {{$renderer('storeYn', 0, storeYn)}}
                                </CCol>
                            </CRow>
                            <CRow v-if="storeYn"  class="mb-2">                                
                                <CCol sm="2">
                                <strong>인증서 등록여부</strong>
                                </CCol>
                                <CCol sm="10">   
                                {{getCertDataYn()}}                                
                                </CCol>
                            </CRow>  
                            </div>
                            <CRow class="mb-1" v-if="!storeYn&&!editable">
                                <CCol sm="2">
                                <strong>앱 다운로드</strong>
                                </CCol>
                                <CCol sm="5">
                                    <CButton
                                    square
                                    size="sm"
                                    @click="downloadApp()"
                                    class="btn-primary"
                                    >
                                    <CIcon
                                        :content="$options.icons.cilDataTransferDown"
                                    />
                                    </CButton>
                                </CCol>
                            </CRow>        
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
            <CCard v-if="storeYn">
                <CCardHeader>
                    <strong> 인증서정보 </strong>
                </CCardHeader>
                <CCardBody>      
                    <CRow>
                        <CCol sm="10">                        
                            <div v-if="osType=='0'">  
                                <CRow class="mb-1">                                
                                    <CCol sm="2">
                                    <strong>인증서 지문</strong>
                                    </CCol>
                                    <CCol sm="10">                                    
                                        <CTextarea 
                                            horizontal
                                            v-model="certData"
                                            :readonly="!editable"
                                        />
                                    </CCol>
                                </CRow>
                            </div>            
                            <div v-if="osType=='1'">           
                            <CRow  class="mb-2">                                
                                <CCol sm="2">
                                <strong>인증서 만료일</strong>
                                </CCol>
                                <CCol sm="10">   
                                {{renderCertInfo($convertDateDay(certExpiredDate))}}
                                </CCol>
                            </CRow>
                            <CRow  class="mb-2">                                
                                <CCol sm="2">
                                <strong>인증서 발급자</strong>
                                </CCol>
                                <CCol sm="10">                                    
                                    {{renderCertInfo(certIssuer)}}
                                </CCol>
                            </CRow>
                            <CRow v-if="certData" class="mb-1">
                                <CCol sm="2">
                                <strong>인증서 초기화</strong>
                                </CCol>
                                <CCol sm="5">
                                    <CButton
                                    square
                                    size="sm"
                                    @click="resetCertData()"
                                    class="btn-primary"
                                    >
                                    <CIcon
                                        :content="$options.icons.cilTrash"
                                        style="color:#ffffff"
                                    />
                                    </CButton>
                                </CCol>
                            </CRow>   
                            </div>
                        </CCol>
                    </CRow>  
                </CCardBody>
             </CCard>
            <CCard>
                <CCardHeader>
                    <strong> 정책 설정 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol sm="7">
                            <!-- <CRow form class="form-group" >
                                <CCol tag="label" sm="3" class="col-form-label font-weight-bold">
                                    탐지정책
                                </CCol>
                            </CRow> -->
                            <CRow form class="form-group">
                                <CCol sm="3">
                                    앱 위변조 탐지
                                </CCol>
                                <CSwitch
                                    color="primary"
                                    :checked.sync="appTamperDetectYn"
                                    labelOn="ON" 
                                    labelOff="OFF"
                                    :disabled="!editable"
                                    />
                            </CRow>
                            <CRow form class="form-group">
                                <CCol sm="3">
                                    OS 위변조 탐지
                                </CCol>
                                <CSwitch
                                    color="primary"
                                    :checked.sync="osTamperDetectYn"
                                    labelOn="ON" 
                                    labelOff="OFF"
                                    :disabled="!editable"
                                    />
                            </CRow>
                            <CRow form class="form-group">
                                <CCol sm="3">
                                    디버깅 탐지
                                </CCol>
                                <CSwitch
                                    color="primary"
                                    :checked.sync="debugDetectYn"
                                    labelOn="ON" 
                                    labelOff="OFF"
                                    :disabled="!editable"                                    
                                    />
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
            <slot name="loading" v-if="loading">                
                <loadingBar/>
            </slot>
          </CCol>
        </CRow>
  </div>
</template>
<script>
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import { cilDataTransferDown, cilTrash} from '@coreui/icons'
export default {
    name: 'AppInfo',
    icons: { cilDataTransferDown, cilTrash},
    components:{
        CIcon
    },    
    props: {
    },
    computed:{        
      appKey () {
        return this.$store.state.dataStore.appInfo_appKey 
      },   
      mode () {
        return this.$store.state.dataStore.appInfo_mode // 0:상세조회, 1:개별수정
      },
    },
    data: function() {
        return {
            previewImgUrl: null, // 미리보기 이미지 URL
            response: null, // 파일 업로드후 응답값    
            appNm: "",
            loading: false, // 로딩바 활성화 여부
            storeYn: false, // 구글플레이앱사인 여부
            certData: "", // 구글플레이 인증서지문
            appTamperDetectYn: true, // 앱위변조탐지 여부
            osTamperDetectYn: true, // OS위변조탐지 여부
            debugDetectYn: true, // 디버깅탐지 여부
            editable:false, // 편집가능상태
            afterClick: false, // 버튼 클릭 직후 여부(버튼 중복 클릭되지 않도록)
            osType: "0",
            packageNm: "",
            appVersion: "",
            osTypeNm: "",
            fileNm: "",
            fileSize: "",
            appInfo: {},
            certExpiredDate: "",// 인증서 만료일자
            certIssuer: "", // 인증서 발급자
        }
    }, 
    mounted(){
        if(this.mode=='0') this.editable = false
        else if(this.mode=='1') this.editable = true
    },
    created () {
        this.selectAppInfo()
    },
    watch:{   
    },
    methods: {
        async formSubmit() {
            if(this.$isEqual(this.appInfo.appNm, this.appNm) 
                && this.$isEqual(this.appInfo.certData,this.certData)
                && this.$isEqual(this.appInfo.appTamperDetectYn, this.appTamperDetectYn)
                && this.$isEqual(this.appInfo.osTamperDetectYn, this.osTamperDetectYn)
                && this.$isEqual(this.appInfo.debugDetectYn, this.debugDetectYn))
            {
                alert("변경된 정보가 없습니다.")
                return false
            }

            if (!this.appNm) {
              alert("앱 이름을 입력해주세요.")
              return false
            }

            var regExp = /[ \{\}\[\]\/?,;:|\)*~`!^\-+┼<>\#$%\'\"\\\(\=]/gi;

            if (regExp.test(this.appNm)) {
                alert("잘못된 입력값입니다.")
                this.appNm = this.appNm.replace(regExp, "");
                return false
            }

            this.afterClick = true            
            let payload = {
                appKey : this.appKey,
                appNm : this.appNm,
                osType: this.osType,
                storeYn : this.storeYn?"1":"0",
                certData:  this.storeYn=="1"?this.certData:null,
                appTamperDetectYn : this.appTamperDetectYn?"1":"0",
                osTamperDetectYn : this.osTamperDetectYn?"1":"0",
                debugDetectYn : this.debugDetectYn?"1":"0"
            }
            this.loading=true 
            try {
                await this.$store.dispatch('appInfo/modifyAppInfo', payload)
                alert("저장하였습니다.")
                if(this.mode=='1') this.$router.go(-1)
                else{
                    this.selectAppInfo()
                    this.editable = false
                    this.loading=false 
                }
            } catch(error){
                this.$response(error)
                this.loading=false
            }
            this.afterClick = false

            return true
        },
        async selectAppInfo() {            
            this.loading=true 
            try {
                await this.$store.dispatch('appInfo/getAppInfo', this.appKey)
                this.appInfo = this.$store.state.appInfo.appInfo
                // 앱정보 셋팅
                this.appNm = this.appInfo.appNm
                this.packageNm = this.appInfo.packageNm
                this.appVersion = this.appInfo.appVersion
                this.osType = this.appInfo.osType
                if(this.appInfo.storeYn == '0'){
                    this.fileNm = this.appInfo.fileNm
                    this.fileSize = this.appInfo.fileSize
                    // 앱 아이콘 조회
                    if(!this.appInfo.appIconFileNm) alert("앱 아이콘 정보를 가져오지 못했습니다.")
                    else{
                        this.previewImgUrl="/api/resources/app-file/icon?fileNm="+this.appInfo.appIconFileNm
                    }
                }
                this.osTypeNm = (this.appInfo.osType=='0'?"ANDROID":"IOS")
                this.storeYn = (this.appInfo.storeYn=='1'?true:false)
                if(this.storeYn)  this.certData = this.appInfo.certData
                this.appTamperDetectYn = this.appInfo.appTamperDetectYn=='1'?true:false
                this.osTamperDetectYn = this.appInfo.osTamperDetectYn=='1'?true:false
                this.debugDetectYn = this.appInfo.debugDetectYn=='1'?true:false
                this.certExpiredDate = this.appInfo.certExpiredDate
                this.certIssuer = this.appInfo.certIssuer
                this.loading=false
            } catch(error){
                this.$response(error)
                this.loading=false
            }

            return true
        },
        changeToModifyMode() {
            this.editable = true
        },
        // 앱 다운로드
        async downloadApp(){
            this.loading =true       
            await this.$downloadApp(this.appKey) 
            this.loading =false     
        },
        cancelAdminInfo(){
            this.editable = false
            this.selectAppInfo()
        },
        // 인증서 초기화
        async resetCertData(){
            if(window.confirm("인증서정보를 초기화하시겠습니까?\n인증서정보가 삭제되고 앱 실행시 인증서정보가 갱신됩니다.")) {
                this.loading =true   
                let payload ={
                    appKey : this.appKey
                }
                 try {
                    await this.$store.dispatch('appInfo/resetCertData', payload)
                    this.loading =false    
                    this.selectAppInfo() 
                 }
                 catch(error){
                    this.$response(error)
                    this.loading =false    
                 }
            }
        },
        getCertDataYn(){
            if(this.certData) return 'O'
            else return 'X'
        },
        renderCertInfo(certInfo){
            if(this.certData) return certInfo
            else return '-'
        }
    }
}
</script>
